@use 'setting' as *;

/* ======================================================================
 p_top
====================================================================== */
.p_top {
  background: $color_21;

  @include sp{

    .c_ttl-m .c_ttl_main.u_en{
      @include fz(44);
    }

  }

  &_banner{
    display: flex;
    justify-content: center;
    margin: 65px auto 0;
    gap: 40px;

    @include sp{
      display: block;
      margin: 40px 0 0 0;
      padding: 0 20px;
      width: 100%;
    }

    &_link{
      display: block;
      flex: 1 1;

      @include sp{
        width: auto;

        &+&{
          margin-top: 20px;
        }

      }

    }

  }

  &_info{
    margin: 60px auto 0;

    @include sp{
      margin: 40px 0 0 0;
    }

    &_slider{
      margin: 20px -15px 0;

      @include sp{
        margin: 10px -20px 0;
        padding: 0 20px;
        overflow: hidden;

        .slick-list{
          overflow: visible;
        }

      }

      .slick-prev,
      .slick-next{
        z-index: 5;
        top: 93px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: $color_1;
        top: calc(50% - 18px);
        @include sp{
          width: 30px;
          height: 30px;
        }

        &:before{
          position: absolute;
          left: 50%;
          top: 50%;
          @include triangle(fff);
          opacity: 1;
          width: 14px;
          height: 12px;
          margin: -7px 0 0 -5px;
          font-size: 0;

          @include sp{
            width: 9px;
            height: 8px;
            margin: -5px 0 0 -3px;
          }

        }

      }

      .slick-prev{
        left: -10px;
        transform: translate(0,-50%) scaleX(-1);
        @include sp{
          left: 10px;
        }

      }
      .slick-next{
        right: -10px;

        @include sp{
          right: 10px;
        }

      }

    }

    &_item{
      margin: 0 15px;

      @include sp{
        margin: 0 20px 0 0;
      }
      &:hover {
        .p_top_info_img_image {
          @include pc {
            opacity: 0.5;
          }
        }
      }
    }

    &_img{
      border-radius: 4px;
      background: $color_1;
      &_image{
        object-fit: cover;
        @include pc {
          transition: opacity .3s;
        }
      }

    }

    &_txt{
      position: relative;
      padding: 10px 0;
      border-bottom: 1px solid $color_3;
      @include fz(14);
      font-weight: bold;

      &:before{
        content: "";
        position: absolute;
        right: 0;
        bottom: -1px;
        width: 20px;
        border-top: 1px solid $color_1;
      }

      &:after{
        position: absolute;
        right: 5px;
        bottom: 14px;
        @include triangle(222);
        width: 9px;
        height: 9px;
      }

    }

  }

  &_course{
    margin: 80px auto 0;
    padding-bottom: 160px;
    background: url(#{$img_dir}/top/course_bg.svg) repeat-x 0 100%;
    animation: bgscroll 50s linear infinite;

    @include sp{
      padding-bottom: 80px;
      background-size: auto 80px;
      background-position: 5% 100%;
    }

    &_lead{
      margin: 30px auto 0;
      @include fz(16);

      @include sp{
        margin: 10px 0 0 0;
        line-height: 1.7;
      }

    }

    &_inner{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 50px auto 0;

      @include sp{
        display: block;
      }

      &:after{
        content: "";
        display: block;
        width: 32.5%;

        @include sp{
          display: none;
        }

      }

    }

    &_item{
      position: relative;
      width: 32.5%;
      margin: 0 0 40px 0;

      @include sp{
        width: auto;
      }

      &:nth-of-type(3n+2){
        top: 30px;

        @include sp{
          top: auto;
        }

      }
      &:nth-of-type(3n){
        top: 60px;

        @include sp{
          top: auto;
        }

      }

      &-musician{

        .p_top_course_img{
          background: $gradient_musician;
        }
        .p_top_course_listitem:before{
          background: $color_13;
        }

      }
      &-performing{

        .p_top_course_img{
          background: $gradient_performing;
        }
        .p_top_course_listitem:before{
          background: $color_49;
        }

      }
      &-sound{

        .p_top_course_img{
          background: $gradient_sound;
        }
        .p_top_course_listitem:before{
          background: $color_57;
        }

      }
      &-movie{

        .p_top_course_img{
          background: $gradient_movie;
        }
        .p_top_course_listitem:before{
          background: $color_67;
        }

      }
      &-photo{

        .p_top_course_img{
          background: $gradient_photo;
        }
        .p_top_course_listitem:before{
          background: $color_6;
        }

      }

    }

    &_link{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      transition: .4s ease;

      @include pc{

        &:hover{
          opacity: .4;
        }

      }

    }

    &_name{
      margin: 0 0 10px 0;
      @include fz(20);
      font-weight: bold;
    }

    &_img{
      padding: 4px 0 0 0;
      border-radius: 0 0 4px 4px;
    }

    &_list{
      min-width: 50%;

      &_wrap{
        display: flex;
        flex-grow: 1;
        margin: 10px 0 0;

        @include sp{

          &-spnowrap{
            display: block;

            .p_top_course_list{
              min-width: initial;
            }

          }

        }

      }

      &-short{
        min-width: initial;
        margin-left: 20px;

        @include sp{
          margin-left: 0;
        }

      }

    }

    &_listitem{
      padding: 5px 0;
      @include fz(14);

      &:before{
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 0 8px 0 0;
        border-radius: 3px;
        vertical-align: middle;

        @include sp{
          vertical-align: 3px;
        }

      }

    }

    &_btn{
      position: relative;
      margin: 5px 0 0 0;
      border-bottom: 1px solid $color_3;
      line-height: 50px;

      &:before {
        top: 10px;
        background: $color_1;
      }

      &:after {
        top: 19px;
        @include sp {
          top: 16px;
        }
      }

    }

  }

  &_opencampus{
    position: relative;
    padding-top: 80px;
    padding-bottom: 100px;

    @include sp{
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 82%;
      height: 100%;
      background: linear-gradient(to right bottom, #8DDBF7 0%, #1BACC7 100%);

      @include sp{
        width: 100%;
      }

    }

    &:after{
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 82%;
      height: 221px;
      background: url(#{$img_dir}/top/opencampus_bg.svg) no-repeat bottom center;

      @include sp{
        display: none;
      }

    }

    >*{
      position: relative;
      z-index: 2;
    }

    &_link{
      display: block;
    }

    &_ttl{
      margin: 40px 0 0 0;
      @include fz(26);
      font-weight: bold;

      @include sp{
        margin: 20px 0 0 0;
        @include fz(24);
        line-height: 1.4;
      }

    }

    &_txt{
      margin: 20px 0 0 0;
      @include fz(18);
      line-height: 1.65;

      @include sp{
        margin: 15px 0 0 0;
        @include fz(16);
        line-height: 1.75;
      }

    }

    &_head{
      display: flex;
      justify-content: space-between;
    }

    &_nav{
      position: relative;
      margin: 15px 0 0;
      @include pc {
        width: 100%;
        height: 50px;
        margin: 20px 0 0;
      }

      &-sp{
        display: none;
      }

      @include sp{
        display: none;

        &-sp{
          display: block;
          width: 100%;
          margin: 10px auto 0;
          position: relative;
          right: -20px;
        }

      }

      &_btn{
        position: absolute;
        z-index: 5;
        top: auto;
        bottom: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: $color_1;
        cursor: pointer;

        @include sp{
          bottom: -20px;
          width: 40px;
          height: 40px;
        }

        &:before{
          position: absolute;
          left: 50%;
          top: 50%;
          @include triangle(fff);
          opacity: 1;
          width: 14px;
          height: 12px;
          margin: -7px 0 0 -5px;
          font-size: 0;
          transition: .4s ease;

          @include sp{
            width: 10px;
            height: 8px;
            margin: -6px 0 0 -4px;
          }

        }

        @include pc{

          &:hover:before{
            transform: translateX(5px);
          }

        }

        &-prev{
          left: auto;
          right: 70px;
          transform: scaleX(-1);

          @include sp{
            left: 0;
            right: auto;
          }

        }

        &-next{
          right: 0;
          transform: none;
        }

        &.swiper-button-disabled{
          background: $color_22;

          @include sp{
            background: $color_3;
          }

          &:before{
            @include triangle(222);
            opacity: .2;

            @include sp{
              opacity: 1;
            }

          }

          @include pc{

            &:hover:before{
              transform: none;
            }

          }

        }

      }

    }

    &_slider{
      padding: 30px 80px 40px;

      @include sp{
        padding: 20px 20px 30px;
      }

      &_wrapper{
        overflow: hidden;
        margin: 0 -80px;

        @include sp{
          margin: 0 -20px;
          padding: 0 40px 20px 0;
        }

      }

    }

    &_item{
      width: 284px;
      margin-right: 40px;
      background: $color_21;
      border-radius: 4px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.11);
      overflow: hidden;

      @include sp{
        width: 86%;
        margin-right: 10px;
      }

    }

    &_img{

      &_image{
        aspect-ratio: 1.91/1;
        object-fit: cover;

        @include sp{
          height: 215px;
        }

      }

    }

    &_inner{
      padding: 15px;

      @include sp{
        padding: 15px;
      }

    }

    &_name{
      display: block;
      @include fz(18);
      font-weight: bold;

      @include sp{
        @include fz(18);
        line-height: 1.4;
      }

    }

    &_desc{
      margin: 10px 0 0 0;
      @include fz(14);
      line-height: 1.7;

      @include sp{
        margin: 10px 0 0 0;
      }

    }

    &_date{
      display: flex;
      margin: 10px 0 0 0;
      @include fz(12);
      line-height: 1.4;
      color: $color_4;
      font-weight: bold;

      @include sp{
        margin: 10px 0 0 0;
        @include fz(12);
      }

      &_lead{
        margin: 0 20px 0 0;
        font-weight: bold;
        color: $color_2;
        white-space: nowrap;
        opacity: .5;

        @include sp{
          margin: 0 10px 0 0;
        }

      }

    }

    &_btn{
      margin: 5px 20px 20px;
    }

    &_scrollbar{
      position: absolute;
      left: 135px;
      bottom: 0;
      top: auto;
      z-index: 50;
      height: 2px;
      width: calc(100% - 270px);
      background: $color_1;

      @include sp{
        bottom: 19px;
        left: 60px;
        width: calc(100% - 120px);
      }

      &_wrap{
        margin: 35px 0 0 0;

        @include sp{
          margin-top: 0;
        }

      }

      &_dot{
        background: $color_1;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin-top: -14px;

        @include sp{
          width: 22px;
          height: 22px;
          margin-top: -10px;
        }

        &:after{
          content: "";
          position: absolute;
          left: 10px;
          top: 10px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background: $color_21;

          @include sp{
            left: 7px;
            top: 7px;
            width: 8px;
            height: 8px;
          }

        }

      }

    }

    &_all{
      margin: 45px 0 0 0;
    }

    &_consultation{
      margin: 80px 0 0 0;

      @include sp{
        margin: 50px 0 0 0;
      }

      &_inner{
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0 0;

        @include sp{
          display: block;
          margin: 20px 0 0 0;
        }

      }

      &_item{
        width: calc(50% - 15px);

        @include sp{
          width: auto;

          &+&{
            margin-top: 40px;
          }

        }

      }

      &_img{
        border-radius: 4px;
      }

      &_txt{
        position: relative;
        background: $color_21;
        margin: -45px 20px 0;
        padding: 30px 20px 20px;
        border-radius: 4px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);

        @include sp{
          margin: -30px 10px 0;
          padding: 17px 15px 15px;
        }

      }

      &_ttl{
        @include fz(20);
        font-weight: bold;

        @include sp{
          @include fz(18);
        }

      }

      &_desc{
        margin: 15px 0 0 0;
        @include fz(14);
        line-height: 1.7;

        @include sp{
          margin: 12px 0 0 0;
        }

      }

      &_btn{
        margin: 20px 0 0 0;

        @include sp{
          margin: 10px 0 0 0;
        }

      }

    }

  }

  &_strength{
    position: relative;
    
    @include sp{
      display: block;
    }

    &_menus{
      position: relative;
      left: 0;
      top: 0;
      width: 50%;
      padding: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include sp{
        position: static;
        float: none;
        width: auto;
        padding: 60px 20px;
        display: block;
      }

    }

    &_bg{
      position: absolute;
      left: 100%;
      top: 50%;
      width: 100%;
      max-width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      //background-attachment: fixed;
      background-image: url(../img/top/strength_ao.jpg);
      transform: translateY(-50%);

      &_inner{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        transition: .6s ease;

        &-0{
          background-image: url(../img/top/strength_ao.jpg);
        }
        &-1{
          background-image: url(../img/top/strength_expo.jpg);
        }
        &-2{
          background-image: url(../img/top/strength_audition.jpg);
        }
        &-3{
          background-image: url(../img/top/strength_multi.jpg);
        }
        &-4{
          background-image: url(../img/top/strength_collabo.jpg);
        }
        &-5{
          background-image: url(../img/top/strength_equipment.jpg);
        }

      }

      &.is-0{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-2{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-3{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-4{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-5{opacity: 0;pointer-events: none;}
      }
      &.is-1{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-2{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-3{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-4{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-5{opacity: 0;pointer-events: none;}
      }
      &.is-2{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-2{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-3{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-4{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-5{opacity: 0;pointer-events: none;}
      }
      &.is-3{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-2{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-3{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-4{opacity: 0;pointer-events: none;}
        .p_top_strength_bg_inner-5{opacity: 0;pointer-events: none;}
      }
      &.is-4{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-2{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-3{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-4{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-5{opacity: 0;pointer-events: none;}
      }
      &.is-5{
        .p_top_strength_bg_inner-0{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-1{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-2{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-3{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-4{opacity: 1;pointer-events: auto;}
        .p_top_strength_bg_inner-5{opacity: 1;pointer-events: auto;}
      }

    }

    &_detail{

      &_img{
        display: block;
        visibility: hidden;
        width: 100%;
        height: 150px;
      }

    }

    &_menu{
      margin: 20px 0 0 0;
      list-style-type: none;
      counter-reset: strength;

      @include sp{
        margin: 10px 0 0 0;
      }

    }

    &_menu_dummy {
      counter-reset: strength;
    }

    &_item_dummy {
      counter-increment: strength;
    }

    &_item{
      border-bottom: 1px solid rgba($color_3,.3);
      counter-increment: strength;
    }

    &_link{
      display: block;
      padding: 20px 0;
      line-height: 1;

      @include sp{
        display: flex;
      }

      &:before,
      &:after{
        opacity: 0;
        transition: .4s ease;

        @include sp{
          display: none;
        }

      }

      &:before{
        top: calc(50% - 15px);
      }
      &:after{
        top: calc(50% - 6px);
      }

    }

    &_img{
      display: none;

      @include sp{
        display: block;
        max-width: 43%;
        min-width: 43%;
        height: 160px;
      }

      img {
        @include sp {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

    }

    &_txt{
      position: relative;
      padding: 0 0 0 60px;
      opacity: .2;
      transition: opacity .1s;

      @include sp{
        padding: 0 0 0 20px;
        opacity: 1;
      }

      &:before{
        content: "Point " counter(strength);
        position: absolute;
        left: 0;
        top: 4px;
        padding: 0 0 2px 0;
        border-bottom: 1px solid $color_1;
        font-family: $roboto;
        @include fz(9);
        font-weight: bold;
        line-height: 1;

        @include sp{
          position: static;
          @include fz(10);
        }

      }

    }

    &_name{
      @include fz(20);
      font-weight: bold;

      @include sp{
        display: block;
        margin: 5px 0 0 0;
        line-height: 1.5;
      }

    }

    &_desc{
      margin-top: 0;
      @include fz(16);
      font-weight: normal;
      line-height: 0;
      visibility: hidden;
      opacity: 0;
      transition: .4s ease;

      @include sp{
        margin-top: 5px !important;
        line-height: 1.5 !important;
        opacity: 1 !important;
        visibility: visible !important;
      }

    }

    &_arrow{
      display: none;

      @include sp{
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 5px 0 0 0;
        background: $color_1;
        border-radius: 10px;

        &:before{
          position: absolute;
          @include triangle(fff);
          width: 7px;
          height: 6px;
          left: 7px;
          top: 6px;
        }

      }

    }

    .is-active{

      .p_top_strength_link{

        &:before,
        &:after{
          opacity: 1;
        }

      }

      .p_top_strength_txt{
        opacity: 1;
      }

      .p_top_strength_desc{
        margin-top: 15px;
        line-height: 1;
        visibility: visible;
        opacity: 1;
      }

    }

  }

  &_sns{
    padding: 0 0 0 20%;
    background: url(#{$img_dir}/top/sns_bg.png) no-repeat left top fixed;
    background-size: auto 100%;

    @include sp{
      padding: 0;
      background: none;
    }

    &_inner{
      padding-top: 100px;
      padding-bottom: 100px;
      background: rgba($color_23,.8);
      backdrop-filter: blur(50px);
      --webkit-backdrop-filter: blur(50px);

      @include sp{
        padding-top: 60px;
        padding-bottom: 45px;
      }

    }

    &_ttl{
      padding: 0 0 0 46px;
      background-position: 0 0;
      background-size: 36px 36px;
      background-repeat: no-repeat;
      @include fz(26);
      font-weight: bold;
      line-height: 36px;
    }

    &_tiktok{
      margin: 40px 0 0 0;

      .p_top_sns_ttl{
        background-image: url(#{$img_dir}/common/icon_tiktok.svg);
      }

      &_head{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &_slider{
        padding: 20px 0 40px 80px;

        @include sp{
          padding-bottom: 0;
        }

        &_wrapper{
          overflow: hidden;
          margin: 0 -80px;

          @include sp{
            margin: 0 -20px 0 -80px;
          }

        }

        .slick-list{
          overflow: visible;
        }

        &_btns{
          position: relative;
          width: 40%;

          .slick-prev,
          .slick-next{
            z-index: 5;
            top: auto;
            bottom: 0;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background: $color_1;

            @include sp{
              width: 40px;
              height: 40px;
            }

            &:before{
              position: absolute;
              left: 50%;
              top: 50%;
              @include triangle(fff);
              opacity: 1;
              width: 14px;
              height: 12px;
              margin: -7px 0 0 -5px;
              font-size: 0;

              @include sp{
                width: 10px;
                height: 8px;
                margin: -6px 0 0 -4px;
              }

            }

          }

          .slick-prev{
            left: auto;
            right: 70px;
            transform: scaleX(-1);

            @include sp{
              right: 60px;
            }

          }
          .slick-next{
            right: 0;
            transform: none;
          }
          .slick-disabled{
            background: $color_22;

            @include sp{
              background: $color_3;
            }

            &:before{
              @include triangle(222);
              opacity: .2;

              @include sp{
                opacity: 1;
              }

            }

          }

        }

      }

      &_item{
        margin-right: 30px;
        border-radius: 4px;
      }

      &_link{
        display: block;
        width: 100%;
        height: 100%;
        transition: .4s ease;

        @include pc{

          &:hover{
            opacity: .4;
          }

        }

      }

      &_thumb{
        width: 246px;
        height: 338px;
        border-radius: 4px;
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.11);

        &_image{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }

      }

      &_name{
        margin: 10px 0 0 0;
        @include fz(14);
      }

    }

    &_double{
      display: flex;
      justify-content: center;
      margin: 40px 0 0 0;

      @include sp{
        display: block;
      }

    }

    &_instagram{
      width: calc(50% - 10px);

      @include sp{
        width: auto;
      }

      .p_top_sns_ttl{
        background-image: url(#{$img_dir}/common/icon_instagram.svg);
      }

      &_list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 20px 0 0 0;

        @include sp{
          margin: 10px 0 0 0;
        }

      }

      &_item{
        width: calc(33.33% - 2px);
        margin: 0 0 2px 0;
      }

    }

    &_twitter{
      width: calc(50% - 10px);

      @include sp{
        width: auto;
        margin: 40px 0 0 0;
      }

      .p_top_sns_ttl{
        background-image: url(#{$img_dir}/common/icon_twitter.svg);
      }

      &_emb{
        position: relative;
        margin: 20px 0 0 0;
        padding-top: 66.5%;

        @include sp{
          margin: 15px 0 0 0;
          padding: 0;
        }

        iframe {/*twitter埋め込みのためclassなし*/
          width: 100% !important;
          height: 100% !important;
          position: absolute !important;
          top:  0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 10px;
          box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.11);

          @include sp{
            position: static !important;
            height: 338px !important;
          }

        }

      }

    }

    &_follow{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0 0 0;
      padding: 20px;
      border-radius: 4px;
      background: rgba($color_21,.8);
      backdrop-filter: blur(50px);
      --webkit-backdrop-filter: blur(50px);

      @include sp{
        display: block;
        padding: 20px 5px;
        background: $color_21;
        backdrop-filter: none;
        --webkit-backdrop-filter: none;
        font-size: 0;
        text-align: center;
      }

      &_lead{
        margin: 0 15px 0 0;
        font-family: $roboto;
        @include fz(19);
        font-weight: bold;

        @include sp{
          display: block;
          margin: 0 0 15px 0;
          @include fz(16);
        }

      }

      &_btn{
        width: 53px;
        margin: 0 15px;

        @include sp{
          display: inline-block;
          width: 46px;
          margin: 0 6px;
        }

        a {
          display: block;
        }

      }

    }

  }

  &_news{
    display: flex;
    justify-content: space-between;
    max-width: 1206px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    @include sp{
      display: block;
      padding: 40px 0 45px;

      &_head{
        padding: 0 20px;
      }

    }

    &_lead{
      @include fz(16);
    }

    &_btn{
      margin: 40px 0 0 0;

      @include sp{
        display: none;
      }

    }

    &_list{
      width: 60%;

      @include sp{
        width: auto;
      }

    }

    &_item{
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 2px solid rgba($color_3,.3);

      @include sp{
        display: block;
        padding: 15px 20px;
        border-bottom-width: 1px;

        &:last-of-type{
          border: none;
        }

      }

    }

    &_date{
      font-family: $montserrat;
      @include fz(12);
      font-weight: bold;
      color: $color_2;

      @include sp{
        line-height: 24px;
      }

    }

    &_cat{
      display: block;
      width: 136px;
      margin: 0 30px 0 20px;
      border-radius: 4px;
      line-height: 24px;
      text-align: center;
      @include fz(10);
      @include a_tag($color_21,$color_21);
      transition: .4s ease;

      @include sp{
        display: inline-block;
        margin: 0 0 0 15px;
      }

      &:hover{
        opacity: .4;
      }

    }

    &_ttl{
      margin: 0 0 0 20px;
      @include fz(14);
      transition: .4s ease;

      @include sp{
        display: block;
        margin: 7px 0 0 0;
        line-height: 1.4;
      }

      &:hover{
        opacity: .4;
      }

    }

  }

}

.p_top_strength {
  height: 600vh;
  @include sp {
    height: auto;
  }
  .p_top_strength_menus {
    &.__dummy {
      opacity: 1;
      height: 100vh;
      @include sp {
        display: none;
      }
      .p_top_strength_bg {
        top: 0;
        transform: translateY(0);
      }
    }
  }
  &.is-show {
  .p_top_strength_menus {
      &.__dummy {
        display: none;
      }
    }
  }
  &_trigger {
    position: absolute;
    left: 0;
    height: 100vh;
    width: 100%;
    &.-trigger1 {
      top: 0;
    }
    &.-trigger2 {
      top: 20%;
    }
    &.-trigger3 {
      top: 33.332%;
    }
    &.-trigger4 {
      top: 49.998%;
    }
    &.-trigger5 {
      top: 66.664%;
    }
    &.-trigger6 {
      top: 83.33%;
    }
  }
  &__inr {
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    min-height: 100%;
    transform: translateY(calc(-50% + 50px));
    opacity: 0;
    transition: opacity .2s ease-out;
    pointer-events: none;
    z-index: -1;
    @include sp {
      position: static;
      opacity: 1;
      transform: none;
    }
    &.is-show {
      opacity: 1;
      pointer-events: all;
      z-index: 1;
    }
  } 
  .gird {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    @include sp {
      height: auto;
      display: block;
    }
  }
}

.p_strength_menus_dummy {
    position: relative;
    left: 0;
    top: 0;
    width: 50%;
    padding: 80px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sp{
      display: none;
    }
    &_menu{
      margin: 20px 0 0 0;
      list-style-type: none;
      counter-reset: strength;

      @include sp{
        margin: 10px 0 0 0;
      }

    }

    &_item{
      border-bottom: 1px solid rgba($color_3,.3);
      counter-increment: strength;
    }

    &_link{
      display: block;
      padding: 20px 0;
      line-height: 1;

      @include sp{
        display: flex;
      }

      &:before,
      &:after{
        opacity: 0;
        transition: .4s ease;

        @include sp{
          display: none;
        }

      }

      &:before{
        top: calc(50% - 15px);
      }
      &:after{
        top: calc(50% - 6px);
      }

    }

    &_img{
      display: none;

      @include sp{
        display: block;
        max-width: 43%;
        min-width: 43%;
      }

    }

    &_txt{
      position: relative;
      padding: 0 0 0 60px;
      opacity: .2;
      transition: opacity .1s;

      @include sp{
        padding: 0 0 0 20px;
        opacity: 1;
      }

      &:before{
        content: "Point " counter(strength);
        position: absolute;
        left: 0;
        top: 4px;
        padding: 0 0 2px 0;
        border-bottom: 1px solid $color_1;
        font-family: $roboto;
        @include fz(9);
        font-weight: bold;
        line-height: 1;

        @include sp{
          position: static;
          @include fz(10);
        }

      }

    }
}

.p_strength_menu_dummy {
  counter-reset: dumstrength;
  &_item{
    counter-increment: dumstrength;
  }
  .p_top_strength_txt {
    &:before{
      content: "Point " counter(dumstrength);
    }
  }
}